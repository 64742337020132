
import { defineComponent, ref, reactive, toRefs, inject, nextTick } from 'vue'

import { ElMessage } from 'element-plus'
import { typeManagementApi } from '@/api/modules/typeManagement'

export default defineComponent({
  name: 'typeManagementAddOrEditDialog',
  setup (props, ctx) {
    // 获取refs
    const addOrEditFormRef = ref()

    // 接收父级的方法
    const updateList: any = inject('updateList')

    // 新增、编辑表单数据
    const addOrEditFormInit = () => {
      return {
        name: '',
        mobile: '',
        role_id: '',
        position_id: '',
        avatar: ''
      }
    }

    const data = reactive({
      addOrEditDialog: false,
      addOrEditType: 0, // 0新增,1编辑
      addOrEditTypeTitle: ['新增', '编辑'],
      addOrEditForm: addOrEditFormInit(),
      addOrEditRules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
      },
      determineBtnLoading: false,

      // 对话框显示
      async showAddOrEdit (id: number) {
        data.addOrEditType = id ? 1 : 0

        // 有id时赋值
        if (id) {
          const { res } = await typeManagementApi.getInfo({ id })
          console.log('根据id获取详情', res)
          data.addOrEditForm = res
        }
        data.addOrEditDialog = true
      },

      // 对话框关闭
      addOrEditCancel () {
        // console.log('对话框关闭')
        addOrEditFormRef.value.resetFields()
        data.addOrEditForm = Object.assign({}, addOrEditFormInit())
      },

      // 确认
      addOrEditDetermine () {
        addOrEditFormRef.value.validate(async (valid: any) => {
          if (!valid) return

          try {
            data.determineBtnLoading = true
            await typeManagementApi.save(data.addOrEditForm)
            ElMessage.success(`${data.addOrEditTypeTitle[data.addOrEditType]}成功`)
            data.addOrEditDialog = false
            data.determineBtnLoading = false

            // 父级更新列表
            updateList('place')
          } catch (error) {
            data.determineBtnLoading = false
          }
        })
      }
    })

    return {
      ...toRefs(data),
      addOrEditFormRef
    }
  }
})
