
import { defineComponent, onBeforeMount, ref, reactive, toRefs, nextTick, provide } from 'vue'

import AddOrEditDialog from './addOrEditDialog.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { typeManagementApi } from '@/api/modules/typeManagement'

export default defineComponent({
  name: 'typeManagementIndex',
  components: {
    AddOrEditDialog
  },
  setup (props, ctx) {
    onBeforeMount(() => {
      data.init()
    })

    // 获取refs
    const tableListRef = ref()
    const addOrEditDialogRef = ref()

    const data: any = reactive({
      list: [],
      loading: true,
      query: {
        page: 1,
        paginate: 20,
        name: ''
      },
      total: 0, // 总条数

      // 初始化
      init () {
        data.query.name = ''
        data.getList('place')
      },

      // 获取列表
      async getList (place: string) {
        // 重置页数
        if (place) {
          data.query.page = 1
          // 滚动条回到顶部
          nextTick(() => {
            tableListRef.value && (tableListRef.value.$el.children[2].scrollTop = 0)
          })
        }

        const { res } = await typeManagementApi.getList(data.query)
        console.log('获取列表', res.data)
        data.loading = false
        data.list = res.data
        data.total = res.total
      },
      // 每页显示条数和当前页码
      handleSizeChange (newSize: number) {
        data.query.paginate = newSize
        data.getList()
        // 滚动条回到顶部
        nextTick(() => {
          tableListRef.value.$el.children[2].scrollTop = 0
        })
      },
      handleCurrentChange (newPage: number) {
        data.query.page = newPage
        data.getList()
        // 滚动条回到顶部
        nextTick(() => {
          tableListRef.value.$el.children[2].scrollTop = 0
        })
      },

      // 新增、编辑
      onAddOrEdit (id: number | string) {
        addOrEditDialogRef.value.showAddOrEdit(id)
      },

      // 删除
      async onDel (id: number) {
        const confirmResult = await ElMessageBox.confirm('是否删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch((err: any) => err)
        if (confirmResult !== 'confirm') return

        await typeManagementApi.del({ id })
        ElMessage.success('删除成功')
        data.getList()
      }

      // // 状态改变
      // async editStatus (item: any) {
      //   // console.log('状态改变', item)
      //   try {
      //     const confirmResult = await ElMessageBox.confirm('是否更变状态？', '提示', {
      //       confirmButtonText: '确定',
      //       cancelButtonText: '取消',
      //       type: 'warning'
      //     }).catch((err) => err)
      //     if (confirmResult !== 'confirm') throw new Error('取消操作')

      //     const form: any = {
      //       user_id: item.user_id,
      //       status: item.status
      //     }
      //     // await membersApi.changeStatus(form)
      //     ElMessage.success('状态改变成功')
      //     data.getList()
      //   } catch (error) {
      //     // 恢复原来的样子
      //     item.status = item.status === 1 ? 2 : 1
      //   }
      // }
    })

    // 注入
    provide('updateList', data.getList)

    return {
      ...toRefs(data),
      tableListRef,
      addOrEditDialogRef
    }
  }
})
